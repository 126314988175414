<template>
  <div>
  <v-dialog v-model="visible">
    <v-card v-if="event && profile">  
      <v-card-title class="display-1">
        Profile Details
        <v-spacer/>
        <v-btn v-if="data.bib_img" outlined class="mr-2" @click="previewBibData=data.bib_img;previewBibDialog=true;" target="_blank">View Bib</v-btn>
        <v-btn v-else-if="event.bib_doc_id" outlined class="mr-2" @click="previewBib">Preview Bib</v-btn>
        <v-btn outlined class="mr-2" :to="{name: 'eventmanagerParticipant', params: {id: event.id, profileId: profile.id}}">Disconnect</v-btn>
        <v-btn v-if="false" dark target="_blank" :to="{name:'adminProfile', params: {profileId: profile.id}}">View Details</v-btn>
      </v-card-title>
      <v-card-text class="mt-4 pb-0">
        <p>
            name: {{ profile.name }}
            • source: {{ profile.provider || 'direct' }}
            • joined: {{ data.join_date | localDate('L LTS') }}
            • dob: {{ profile.dob | localDate('L') }}
            • email: {{ profile.email }}
            • gender: {{ profile.gender }}
            • id: {{ profile.id }}
            <span v-if="profile.providers && this.profile.providers.length > 0">
              • Connected using: <strong v-if="false">{{profile.providers.join(' + ')}}</strong>
              <template  v-for="(provider, idx) in profile.providers">
                <span v-if="idx > 0" class="px-1" :key="`sep_${provider}`">•</span>
                <v-btn v-if="oauthProviders.canSyncRemotely(provider)" text small class="px-1" :key="provider" @click="requestSyncProvider(provider)">
                  {{provider}}
                  <v-icon x-small class="ml-1">fal fa-sync</v-icon>
                </v-btn>
                <span v-else class="px-1" :key="provider">
                  {{provider}}
                </span>
              </template>
            </span>
            <span v-else>• No device connected</span>
            <span v-if="profile.manual" class="font-weight-bold">• MANUAL CONNECT</span>
            <span v-if="profile.private" class="font-weight-bold">* PRIVATE PROFILE</span>
        </p>
        <p>
          <strong>Fit goals</strong>
          • steps: {{ profile.step_goal || '(not set)' | steps }}
          • active time: {{ profile.time_goal || '(not set)' | duration }}
          • kcal: {{ profile.kcal_goal || '(not set)' }}
          
        </p>
        <p v-if="event.verification_method === 'ALLOWLIST'">
<!-- TODO: <v-btn text color="accent" @click="changeDisplayName();"><v-icon small class="mr-2">fadl fa fa-pencil</v-icon> Change Display Name</v-btn> -->      
        </p>
      </v-card-text>
      <div v-if="data.push_enabled && showDebugInfo">
        <!-- todo: hidden until app is updated -->
        <v-card-subtitle class="my-0 py-0 d-flex align-center">
          <v-icon small color="green" class="me-2">fa-check-circle</v-icon>
          <span>App connected</span>
          <v-btn icon @click="refreshAppStatus" :loading="refreshingAppStatus"><v-icon small>fa-info-circle</v-icon></v-btn>
          <v-spacer />
          <v-btn v-if="!refreshingAppStatus && data.meta && data.meta.some(x => x.key == 'dbg.app')" text @click="$refs.appDebugInfo.open()">App Info</v-btn>
          <v-btn v-if="!refreshingAppStatus && data.meta && data.meta.some(x => x.key == 'dbg.sync')" text @click="$refs.appSyncInfo.open()">Sync Info</v-btn>
          <v-btn v-if="!refreshingAppStatus" outlined @click="refreshAppSync" :loading="$store.getters.isLoading">Sync</v-btn>
        </v-card-subtitle>
        <MultiStepDialog ref="appDebugInfo" title="App debug info" single-step>
          <pre>{{ JSON.stringify(JSON.parse((data.meta && data.meta.find(x => x.key == 'dbg.app') || {}).val_str || '{}'), null, 2) }}</pre>
        </MultiStepDialog>
        <MultiStepDialog ref="appSyncInfo" title="Sync debug info" single-step>
          <pre>{{ JSON.stringify(JSON.parse((data.meta && data.meta.find(x => x.key == 'dbg.sync') || {}).val_str || '{}'), null, 2) }}</pre>
        </MultiStepDialog>
      </div>
      <v-card-title class="subtitle">Registration</v-card-title>
      <v-card-text class="mt-4 pb-0">
        <p>
            Code: <strong>{{ data.verification_code || 'n/a' }}</strong>
            • joined: {{ data.join_event_date | localDate('L LTS') }}
            <span v-if="data.reg">
            • given name: {{ data.reg.gn }}
            • family name: {{ data.reg.fn }}
            • group/team: {{ data.reg.g }}
            </span>
            <span v-else>
            • name: {{ profile.name }}
              (not linked to registration record)
            </span>
            <span v-if="data.udf_1">• {{ event.join_udf_1_field ? event.join_udf_1_field.label : 'field 1' }}: {{ data.udf_1 }}</span>
            <span v-if="data.udf_2">• {{ event.join_udf_2_field ? event.join_udf_2_field.label : 'field 2' }}: {{ data.udf_2 }}</span>
            <span v-if="data.udf_3">• {{ event.join_udf_3_field ? event.join_udf_3_field.label : 'field 3' }}: {{ data.udf_3 }}</span>
            <span v-if="showRewardBadgeOption">• 
              <v-btn text small color="accent" @click="rewardBadgeDialogVisible = true">
                <v-icon small class="me-2">fadl fa fa-shield</v-icon> 
                Reward Badge
              </v-btn> 
            </span>
            <v-dialog v-model="showAwardDialog" max-width="400px">
              <template v-slot:activator="{ on }">
                <span>
                  • 
                  <v-btn text small color="accent" v-on="on">
                    <v-icon small class="mr-2">fadl fa fa-award</v-icon> 
                    <span v-if="data.award_cat && data.award_pos">#{{data.award_pos}} {{data.award_cat}}</span>
                    <span v-else>award</span>
                  </v-btn> 
                </span>
              </template>
              <v-card>
                <v-card-title>Award</v-card-title>
                <v-divider></v-divider>
                <v-card-text class="pt-4">
                  <p>You can show an optional apply an award for this participant. This will show up on the leaderboards as a badge next to the name. The award will apply to all leaderboards within this event.</p>
                  <v-text-field v-model="data.award_cat" label="Category" />
                  <v-text-field v-model.number="data.award_pos" label="Position" />
                  <p class="text-muted">Note: it might take 10-15 minutes to reflect this change on all leaderboards.</p>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" @click="awardParticipant" :loading="$store.getters.isLoading">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> 
            <v-btn text small color="accent" @click="participantDetailsDialog=true">
              <v-icon small class="mr-2">fadl fa fa-cogs</v-icon> settings
            </v-btn>
            <v-dialog v-model="participantDetailsDialog" scrollable max-width="600px">
              <v-card>
                <v-card-title>Participant details</v-card-title>
                <v-divider class="mb-4"></v-divider>
                <v-card-text>
                  <ToggleButtonInput 
                    v-model="participantDetails.manual_entry" 
                    label="Manual Entry Mode" 
                    small 
                    :items="[ { text: 'Event Default', type: null }, { text: 'Blocked', type: 'BLOCKED' }, { text: 'Allowed', type: 'ALLOWED' }, { text: 'Allow & pre-approve', type: 'PRE_APPROVED' }, ]" 
                    hint="This allows you to override manual entry rules for this specific user." 
                    />
                </v-card-text> 
                <v-divider class="mb-4"></v-divider>
                <v-card-text>
                  <p class="mb-0">Activity Filtering:</p>
                  <p class="text-muted">Leave blank to follow the event rules.</p>
                  <DateWithTimeZonePicker v-model="participantDetails.activities_from" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Only activities on or after (included)" class="pt-0 mt-4" />
                  <DateWithTimeZonePicker v-model="participantDetails.activities_till" :timeZone="event.timeZoneOlson" clearable :minDate="event.from" :maxDate="event.till" label="Only activities before (excluded)" class="pt-0 mt-0" />
                </v-card-text>
                <v-divider class="mb-4"></v-divider>
                <v-card-text v-if="event.join_udf_1_field || event.join_udf_2_field || event.join_udf_3_field">
                  <p class="mb-0">Custom fields:</p>
                  <p class="text-muted">Please note that changing these fields will not update team or leaderboard assignments.</p>
                  <RegFieldInput v-model="participantDetails.udf_1" :field="event.join_udf_1_field" :eventId="event.id" />
                  <RegFieldInput v-model="participantDetails.udf_2" :field="event.join_udf_2_field" :eventId="event.id" />
                  <RegFieldInput v-model="participantDetails.udf_3" :field="event.join_udf_3_field" :eventId="event.id" />
                </v-card-text>
                <v-divider class="mb-4"></v-divider>
                <v-card-text v-if="showLeaderboardSelection" style="overflow-y: auto;">
                  <p>Select the leaderboard(s) this participant may participate in. Deselect all to connect with all leaderboards.</p>
                  <v-checkbox v-for="(item, idx) in event.races" :key="idx" v-model="participantDetails.race_ids" :label="item.name" :value="item.id" hide-details dense />
                  <p class="mt-4 text-hint">Saving changes will reprocess all activities of this participant to match them with the new settings.</p>
                </v-card-text>
                <v-divider v-if="showLeaderboardSelection"></v-divider>
                <v-card-actions>
                  <v-btn color="accent" text @click="participantDetailsDialog = false">Close</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="accent" @click="updateParticipant" :loading="$store.getters.isLoading">Save</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog> 
        </p>
        <p v-if="showLeaderboardSelection">
            Connected to leaderboard(s): {{ data.races ? data.races.join(', ') : 'all' }}
            <v-btn text small color="accent" @click="participantDetailsDialog=true">
              <v-icon small class="mr-2">fadl fa fa-pencil</v-icon> edit
            </v-btn>
        </p>
        <p v-if="data.on_demand_race_starts">
          On-demand leaderboards: 
          <v-chip v-for="(item, idx) in Object.entries(data.on_demand_race_starts)" :key="idx" outlined  @click:close="disconnectOnDemandRace(item[0])" close class="ms-2" :title="`Started: ${item[1]}`">
            {{item[0]}}
          </v-chip>
        </p>
        
        <p v-if="(data.race_ids && data.race_ids.some(id => event.races.find(x => x.id == id && x.team))) || (!data.race_ids && ((data.reg && data.reg.g) || !data.reg) && event.races.some(x => x.team)) || event.join_random_team_assignment || event.join_select_team || data.groups.length">
          Connected to team(s): 
          <v-chip v-for="(group, idx) in data.groups" :key="idx" target="_blank" :to="{name: 'profileGroupsEvent', params: {id: group.id, eventId: event.id}}" @click:close="leaveTeam(group)" close close-icon="fa-user-minus" class="ms-2">
            <v-icon small class="mr-2">fadl fa fa-info-circle</v-icon> {{group.name}} <span class="mx-1">&nbsp;</span>
          </v-chip>
          <strong v-if="!data.groups || data.groups.length == 0"><v-icon small color="warning">fadl fa fa-exclamation-triangle</v-icon> Not connected to a team</strong>
          <strong v-if="!event.join_random_team_assignment && data.reg && !data.reg.g && !(data.groups&&data.groups.length)"><v-icon small color="warning">fadl fa fa-exclamation-triangle</v-icon> No team name imported! Please re-import with a team name and sync team.</strong>
          <v-btn v-if="data.reg && data.reg.g" text color="accent" @click="resyncTeams(); "><v-icon small class="mr-2">fadl fa fa-sync</v-icon> Resync</v-btn>
          
          <v-btn v-if="!showTeamCategoryOption && (!data.groups || data.groups.length == 0) && (!data.reg || (data.reg && !data.reg.g))" text color="accent" @click="openJoinTeamDialog(); "><v-icon small class="mr-2">fadl fa fa-user-plus</v-icon> Assign</v-btn>
          <v-btn v-if="showTeamCategoryOption" text color="accent" @click="openJoinTeamDialog(); "><v-icon small class="mr-2">fadl fa fa-user-plus</v-icon> Assign</v-btn>
        </p>

        <v-dialog v-model="joinTeamDialogVisible" max-width="400px">
          <v-card>
            <v-card-title>{{$t('events.welcome.join-team-join-a-team')}}</v-card-title>
            <v-card-text>
              {{$t('events.welcome.join-join-team-validation-msg')}}
            </v-card-text>
            <v-divider></v-divider>
            <v-card-text>
              <v-autocomplete
                v-model="selectedGroupId"
                :items="availableGroups"
                :label="$t('events.welcome.join-team-select')"
                item-text="name"
                item-value="id"
                />
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-btn color="secondary" text @click="joinTeamDialogVisible = false">{{$t('shared.cancel')}}</v-btn>
              <v-spacer></v-spacer>
              <v-btn color="secondary" :disabled="!selectedGroupId" @click="joinTeam" :loading="$store.getters.isLoading">{{$t('shared.confirm')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>              
        
        <v-alert v-if="event.allowed_history_days || event.allowed_history_days === 0" type="info">
          Please note this event has back-dating limitation of {{event.allowed_history_days}} days. 
          Only activities <strong>AFTER</strong> {{ firstDayAllowed | localDate('L LTS') }} may count!
        </v-alert>
        <v-alert v-if="event.max_participation_days" type="info">
          Please note this event has a max participation duration of {{event.max_participation_days}} days. 
          Only activities <strong>BEFORE</strong> {{ lastDayAllowed | localDate('L LTS') }} may count!
        </v-alert>

        <v-alert v-if="data.activities_from || data.activities_till" type="info">
          Please note this user has configured a custom activity filter to restrict which of their activities may count towards this event. 
          <span v-if="data.activities_from && data.activities_till">Only activities between {{ data.activities_from | localDate }} (included) and {{ data.activities_till | localDate }} (excluded) may count.</span>
          <span v-else-if="data.activities_from">Only activities on or after {{ data.activities_from | localDate }} may count.</span>
          <span v-else-if="data.activities_till">Only activities before {{ data.activities_till | localDate }} may count.</span>
        </v-alert>
      </v-card-text>

      <div v-if="data.race_results && data.race_results.length == 0">
        <v-card-text>
          <i>No results yet for this event.</i>
        </v-card-text>
      </div>
      <div v-if="data.race_results && data.race_results.length > 0">
        <MyRaceResultsOverview :results="data.race_results" title="Results for this event" adminMode :profileId="profile.id" @refresh="refresh"/>
      </div>

      <v-card-title class="subtitle">
        Last 25 activities
        <v-spacer/>
        <v-btn v-if="!activityPermissionError" :disabled="eventIsLocked" outlined color="secondary" class="mr-2" @click="showActivityEditorForAdd">Add</v-btn>
        <v-btn :disabled="eventIsLocked" outlined color="primary" @click="reprocessActivities" :loading="$store.getters.isLoading">Reprocess activities</v-btn>
      </v-card-title>
      <v-alert v-if="eventIsLocked" type="warning" tile>
        Leaderboards are locked for this event. No changes are possible.
        <v-btn outlined :to="{name:'eventmanagerLock', params: {id: event.id}}">Manage</v-btn>
      </v-alert>
      <v-alert v-if="activityPermissionError" type="info" tile>Access to user (fitness) data is limited for this event. If you don't see any data it might be because you don't have permissions to view user fitness data. Please contact the main admin for details.</v-alert>
      <v-card-text v-if="!activityPermissionError">Dates and times are in your local time zone. Only activities that can potentially qualify are shown.</v-card-text>
      <v-simple-table fixed-header v-if="activities && activities.data.length > 0" >
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left">Valid</th>
              <th class="text-left">{{$t('profile.activities.sport')}}</th>
              <th class="text-left">{{$t('profile.activities.source')}}</th>
              <th class="text-left">{{$t('profile.activities.date')}}</th>
              <th class="text-left">{{$t('profile.activities.time')}}</th>
              <th class="text-left">Result</th>
              <th class="text-left">Actions</th>
              <th class="text-left">Value(s)</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, idx) in activities.data" :key="'act'+idx">
              <td class="pr-0" style="width:30px;">
                <v-icon small v-if="item.validation_for_event.status == 'OK'" color="success" title="Valid activity">fadl fa fa-check</v-icon>
                <v-icon small v-else color="warning" :title="item.validation_for_event.msg">fadl fa fa-exclamation-triangle</v-icon>
              </td>
              <td class="pr-0" style="width:30px;"><v-icon :title="item.type">{{$helpers.getActivityIcon(item.type)}}</v-icon></td>
              <td class="pr-0" style="width:30px;"><v-icon :title="item.provider">{{$helpers.getProviderIcon(item.provider)}}</v-icon></td>
              <td>
                <span :title="`Created on ${$options.filters.localDate(item.created, 'L LTS', /*inUserTime:*/true)}, raw start: ${item.start}`">{{ item.start | localDate('L LTS', /*inUserTime:*/true) }}</span>
                <span v-if="item.modified">(MODIFIED)</span>
              </td>
              <td>{{ item.time_s | duration }} </td>
              <td v-if="item.type == 'STAIR_CLIMBING'">{{ item.custom }} stairs </td>
              <td v-else-if="item.type == 'CUSTOM'">{{ item.name }}<span v-if="item.custom">: {{ item.custom }}x</span> </td>
              <td v-else-if="item.type == 'DAILY_SUMMARY'">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-if="item.provider=='whoop'" v-bind="attrs" v-on="on">Strain: {{ item.custom }}</span>
                    <span v-else v-bind="attrs" v-on="on">{{ item.steps }} steps </span>
                  </template>
                  <span>
                    <span v-if="item.provider=='whoop'">Strain: {{ item.custom }}<br/></span>
                    Steps: {{item.steps | steps}} / {{item.step_goal || '(no goal)' | steps}}<br/>
                    Mins: {{item.time_s | duration}} / {{(item.time_goal || '(no goal)') | duration}}<br/>
                    Kcal: {{item.kcal || '-'}} / {{item.kcal_goal || '(no goal)'}}<br/>
                    Stands: {{item.stands || '-'}} / {{item.stand_goal || '(no goal)'}} (Apple Watch only)<br/>
                  </span>
                </v-tooltip>
              </td>
              <td v-else>{{ item.dist | distance(event.unit, /*ignoreUnit:*/false, item.type) }}</td>
              <td>
                <v-btn v-if="item.validation_for_event.status !== 'OK'" small icon title="Approve activity" @click="approveActivity(item.id)"><v-icon small color="accent">fal fa-check-circle</v-icon></v-btn>
                <v-btn v-if="hasTrackChallengeOrGeofence" small icon title="View/approve the GPS track data" @click="showActivityDetails(item.id)"><v-icon small color="accent">fal fa-map</v-icon></v-btn>
                <v-btn v-if="item.score_by_race && Object.keys(item.score_by_race).length" small icon title="Reject activity" @click="rejectActivity(item.id)"><v-icon small color="accent">fal fa-times-circle</v-icon></v-btn>
                <v-btn v-if="item.provider!='result'" small icon @click="showActivityEditor(item.id)"><v-icon small color="accent">fal fa-pencil</v-icon></v-btn>
                <v-btn v-if="item.provider=='manual_entry' || item.provider=='quick_entry' || item.provider=='import'" small icon @click="deleteActivity(item.id)"><v-icon small color="accent">fal fa-trash</v-icon></v-btn>
              </td>
              <td v-if="item.score_by_race">
                <span v-for="(raceId,idx) in Object.keys(item.score_by_race)" :key="idx">
                  <span v-if="idx != 0">•</span>
                  <span v-if="item.score_by_race[raceId] === 0" class="text-muted" :title="`Leaderboard: ${raceId}`">✔️</span>
                  <span v-else class="" :title="`Leaderboard: ${raceId}`">{{ $helpers.getValueForDisplay($options, event.races.find(x => x.id===raceId), {score_value: item.score_by_race[raceId]}, event)}}</span>
                </span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>      
      <div v-if="activities && activities.meta && activities.meta.has_more" class="full-width-separator text-center mt-6">
        <v-btn class="" rounded outlined @click="loadMoreActivities">{{$t('shared.load-more-data')}}</v-btn>
      </div>
      <div v-if="activities && activities.data.length == 0">
        <v-card-text>
          <i>No activities entered yet.</i>
        </v-card-text>
      </div>
      <div v-if="photos && photos.data && photos.data.length">
        <h3>Recent photos</h3>
        <photo-gallery :photos="photos.data" />
      </div>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="visible = false">Close</v-btn>
      </v-card-actions>      
    </v-card>
  </v-dialog>
  <v-dialog v-model="activityDialogVisible">
    <v-card>  
      <v-card-title class="display-1">
        Activity Details
      </v-card-title>
      <v-card-text v-if="activity && hasTrackChallengeOrGeofence" class="mt-4 pb-0">
        Red line shows the track races in this event and blue line shows the actual activity. It should more or less overlap a red line to count.
      </v-card-text>
      <v-row v-if="activity" class="mx-4 my-0">
        <v-col class="grow">
        <v-select
          :items="event.races"
          v-model="selectedRaceId" 
          item-value="id"
          item-text="name"
          placeholder="Select Race"
          label="Select Race"
          
          />
        </v-col>
        <v-col class="grow">
        <v-select
          :items="badgesForSelectedRace"
          v-model="selectedBadgeId" 
          item-value="id"
          item-text="name"
          placeholder="Select Badge (optional)"
          label="Select Badge (optional)"
          />
        </v-col>
        <v-col class="shrink">
          <v-btn color="primary" :disabled="!selectedRaceId" @click="approveRaceForActivity">Approve</v-btn>
        </v-col>
      </v-row>
      <LeafletMap 
        v-if="hasTrackChallengeOrGeofence"
        ref="leaflet"
        style="height: 500px; width: 100%; z-index: 2;" 
        :zoom="4"
        />
      <v-card-actions>
        <v-btn text @click="activityDialogVisible=false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="rewardBadgeDialogVisible" max-width="600">
    <v-card>  
      <v-card-title class="display-1">
        Reward a Badge 
      </v-card-title>
      <v-card-text class="mt-4 pb-0">
        You can reward badge on behalf of this user.
      </v-card-text>
      <v-row class="mx-4 my-0">
        <v-col class="grow">
        <v-select
          :items="event.races"
          v-model="selectedRaceId" 
          item-value="id"
          item-text="name"
          placeholder="Select Leaderboard"
          label="Select Leaderboard"          
          />
        </v-col>
        <v-col class="grow">
        <v-select
          :items="badgesForSelectedRace"
          v-model="selectedBadgeId" 
          item-value="id"
          item-text="name"
          placeholder="Select Badge"
          label="Select Badge"
          />
        </v-col>
      </v-row>
      <v-row class="mx-4 my-0">
        <v-col class="grow">
        <DateAndTimeWithTimeZonePicker 
          v-model="selectedDate" 
          :timeZone="event.timezone" 
          :label="$t('profile.activities.entry.activity-date-start')" 
          :timeLabel="$t('profile.activities.entry.activity-time-start')" 
          />
        </v-col>
      </v-row>
      <v-card-actions>
        <v-btn text @click="rewardBadgeDialogVisible=false">Close</v-btn>
        <v-spacer />
        <v-btn color="primary" :disabled="!selectedRaceId || !selectedBadgeId || !selectedDate" @click="rewardBadge" :loading="$store.getters.isLoading">Reward Badge</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="activityEditDialogVisible" width="600">
    <v-card v-if="activity">  
      <v-card-title class="display-1">
        {{ activity.id ? 'Edit an activity' : 'Add an activity' }}
      </v-card-title>
      <v-card-text v-if="activity" class="mt-4 pb-0">
        <v-select
          :items="siteData.activities"
          v-model="activity.type" 
          item-value="type"
          item-text="text"
          label="Activity Type"
          />
        <DateAndTimeWithTimeZonePicker 
          v-model="activity.start" 
          :timeZone="event.timezone" 
          :label="$t('profile.activities.entry.activity-date-start')" 
          :timeLabel="$t('profile.activities.entry.activity-time-start')" 
          />
        <v-row>
          <v-col cols="6">
            <DistanceTextArea
              v-if="activity.type === 'DAILY_SUMMARY'"
              v-model="activity.steps" 
              icon="fa-shoe-prints"
              :mode="$helpers.UnitType.NUMBER"
              :label="$t('profile.activities.steps')" 
              />
            <DistanceTextArea
              v-else
              v-model="activity.dist" 
              icon="fa-ruler"
              :unit="activityEntryUnit"
              :label="$t('profile.activities.entry.distance-label', { unit: activityEntryUnit, requirement: $t('shared.required') } )" 
              />
          </v-col>
          <v-col cols="6">
              <DistanceTextArea
                v-if="showElevation"
                v-model="computedElevationGain" 
                icon="fa-mountain"
                :mode="$helpers.UnitType.ELEVATION"
                :unit="activityEntryUnit"
                :label="$t('profile.activities.entry.elevation-label', {unit: elevationUnitTypeDisplay})" 
                />

          </v-col>
        </v-row>
        <DurationTextArea
          v-model="activity.time_s" 
          icon="fa-clock"
          :label="$t('profile.activities.entry.duration')" 
          />
        <DistanceTextArea
          v-if="showCustomField"
          v-model="activity.custom" 
          icon="fa-tally"
          :mode="$helpers.UnitType.NUMBER"
          :unit="unitType"
          :label="$t('profile.activities.entry.custom')" 
          :hint="customFieldLabel"
          />
        <v-switch v-if="!activity.id" v-model="activity.preapproved" label="Add as a pre-approved (bonus) activity" class="mt-4" />
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="activityEditDialogVisible=false">Close</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="saveActivity" :loading="$store.getters.isLoading">Save</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="previewBibDialog" width="600">
    <v-card>
      <v-progress-circular v-if="!previewBibData" indeterminate :size="100" :width="5" color="primary" class="mx-a"></v-progress-circular>
      <img v-if="previewBibData" :src="previewBibData" style="max-width:100%;" />
      <v-card-actions>
        <v-btn text @click="previewBibDialog=false">Close</v-btn>
        <v-spacer/>
        <span v-if="previewBibData" class="text-muted">Right click the image and select 'Save Image As...' to save this image.</span>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="syncProviderDialogVisible" width="450">
    <v-card>
      <v-card-title>Sync data from <strong class="ml-1">{{syncProviderName}}</strong></v-card-title>
      <v-card-text>
        <p>Request a (re)sync from the selected provider. Note that it might take a few minutes for data to show.</p>
        <p>From how many days back should we load data?</p>
        <v-text-field v-model.number="syncProviderDays" label="Number of days"/>
      </v-card-text>
      <v-card-actions>
        <v-btn text @click="syncProviderDialogVisible=false">Close</v-btn>
        <v-spacer/>
        <v-btn color="primary" @click="syncProvider" :loading="$store.getters.isLoading">Sync Now</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  </div>
</template>

<script>
import eventManagerService from "@/services/eventManagerService";
import groupService from "@/services/groupService";
import profileService from "@/services/profileService";
import activityService from "@/services/activityService";
import ActivityGrid from "@/components/ActivityGrid";
import MyRaceResultsOverview from "@/components/MyRaceResultsOverview";
import ToggleButtonInput from "@/components/ToggleButtonInput";
import DateWithTimeZonePicker from "@/components/DateWithTimeZonePicker";
import DateAndTimeWithTimeZonePicker from "@/components/DateAndTimeWithTimeZonePicker";
import DistanceTextArea from "@/components/DistanceTextArea";
import DurationTextArea from "@/components/DurationTextArea";
import RegFieldInput from '@/components/RegFieldInput.vue';
import LeafletMap from "@/components/LeafletMap";
import { DateTime } from 'luxon'
import PolylineUtil from "@/plugins/Polyline.encoded.js";
import siteData from '@/data/site.json'
import PhotoGallery from '@/components/PhotoGallery.vue';
import MultiStepDialog from '@/components/generic/MultiStepDialog.vue';
import oauthProviders from '@/util/oauthProviders.js'

export default {
  name: "ProfileDetailsDialog",
  components: {
    ActivityGrid,
    MyRaceResultsOverview,
    LeafletMap,
    ToggleButtonInput,
    RegFieldInput,
    DateWithTimeZonePicker,
    DateAndTimeWithTimeZonePicker,
    DistanceTextArea,
    DurationTextArea,
    PhotoGallery,
    MultiStepDialog,
  },
  props: {
      eventId: String,
      event: Object,
      profileId: String,
  },
  data() {
    return {
      siteData: siteData,
      oauthProviders: oauthProviders,
      visible: false,
      data: null,
      profile: null,
      activities: null,
      photos: null,
      activityPermissionError: false,
      participantDetailsDialog: false,
      selectedRaceId: null,
      selectedBadgeId: null,
      selectedDate: null,
      badgesForSelectedRace: [],
      activityDialogVisible: false,
      activityEditDialogVisible: false,
      rewardBadgeDialogVisible: false,
      showAwardDialog: false,
      activity: null,
      previewBibDialog: false,
      previewBibData: null,
      joinTeamDialogVisible: false,
      availableGroups: null,
      selectedGroupId: null,
      syncProviderDialogVisible: false,
      syncProviderName: null,
      syncProviderDays: 7,
      participantDetails: {},
      refreshingAppStatus: false,
    };
  },
  async mounted() {

  },
  methods: {
    async openForEvent(profile, eventId) {
        this.profileId = profile.id;
        this.eventId = eventId;
        this.event = (await eventManagerService.get(this.eventId)).data;
        this.open();
    },

    async open(profileId) {
        profileId = profileId || this.profileId;
        this.visible = true;
        let response = await eventManagerService.getProfile(this.eventId, profileId);
        this.data = response.data;
        this.participantDetails = {
          race_ids: this.data.race_ids || [],
          manual_entry: this.data.manual_entry,
          udf_1: this.data.udf_1,
          udf_2: this.data.udf_2,
          udf_3: this.data.udf_3,
          activities_from: this.data.activities_from,
          activities_till: this.data.activities_till,
        }
        this.profile = this.data.profile;
        this.teams = this.data.groups;
        try {
          this.activities = (await eventManagerService.getProfileActivities(this.eventId, profileId)).data;
        }
        catch {
          this.activityPermissionError = true;
        }
        this.photos = (await eventManagerService.getParticipantPhotos(this.eventId, profileId)).data
        console.log('Admin profile details dialog', this.visible, this.profile, this.data, this.photos);
    },

    async loadMoreActivities() {
        this.activities = (await eventManagerService.getProfileActivities(this.eventId, this.profile.id, this.activities.meta.continuation_token)).data;
    },

    async changeDisplayName() {
      // todo
    },

    async changeTeamName() {
      // todo
    },

    async openJoinTeamDialog() {
      this.joinTeamDialogVisible = true;
      var response = await groupService.getGroupsInEvent(this.event.id, "*");
      this.availableGroups = response.data.data;
    },

    async reprocessActivities() {
        const response = (await eventManagerService.reprocessParticipant(this.eventId, this.profile.id)).data;
        this.$helpers.toastResponse(this, response, 'Reprocessed data successfully.');
        this.open(this.profile.id); 
    },

    async resyncTeams() {
        const response = (await eventManagerService.syncTeamsForParticipant(this.eventId, this.profile.id)).data;
        this.$helpers.toastResponse(this, response, 'Synced teams successfully.');
        this.open(this.profile.id); 
    },
    async joinTeam() {
        const response = (await eventManagerService.joinTeamsForParticipant(this.eventId, this.profile.id, this.selectedGroupId)).data;
        this.$helpers.toastResponse(this, response, 'Joined teams successfully.');
        this.joinTeamDialogVisible = false;
        this.open(this.profile.id); 
    },
    async leaveTeam(group) {
      const member = this.profile;
      if (confirm(`Are you sure you want to remove ${member.name} from the team ${group.name}?`)) {
        const response = (await profileService.disconnectGroupAndEvent(group.id, member.id, this.event.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully removed ${member.name} from the team ${group.name}`);
        this.open(this.profile.id); 
      }
    },
    async disconnectOnDemandRace(raceId) {
      const member = this.profile;
      if (confirm(`Are you sure you want to remove ${member.name} from the on-demand leaderboard ${raceId}? This will also remove their result (if any) from this leaderboard.`)) {
        const response = (await eventManagerService.disconnectFromOnDemandRace(this.event.id, raceId, member.id)).data;
        this.$helpers.toastResponse(this, response, `Successfully removed ${member.name} from the leaderboard ${raceId}`);
        this.open(this.profile.id); 
      }
    },
    async awardParticipant() {
      const member = this.profile;
      const response = (await eventManagerService.awardParticipant(this.event.id, member.id, this.data.award_cat, this.data.award_pos)).data;
      this.$helpers.toastResponse(this, response, `Successfully rewarded the award.`);
      this.showAwardDialog = false;
      this.open(this.profile.id); 
    },

    async refresh() {
        this.open(this.profile.id); 
    },

    async requestSyncProvider(provider) {
      this.syncProviderName = provider;
      this.syncProviderDialogVisible = true;
    },
    async syncProvider() {
      var response = await eventManagerService.requestParticipantProviderSync(this.event.id, this.profile.id, this.syncProviderName, this.syncProviderDays);
      this.$helpers.toastResponse(this, response.data, 'Data sync requested. Data might take several minutes to arrive.')
      if (response.data.status == "OK") {
        this.refresh();
        this.syncProviderDialogVisible = false;
      }
    },

    async previewBib() {
      this.previewBibDialog = true;
      var response = await eventManagerService.getBibPreview(this.event.id, this.profile.id);
      let reader = new FileReader();
      reader.readAsDataURL(response.data); 
      reader.onload = () => {
          this.previewBibData = reader.result;
      };
    },

    async approveRaceForActivity() {
        const response = (await eventManagerService.approveParticipantActivityDetails(this.eventId, this.profile.id, this.activity.id, this.selectedRaceId, this.selectedBadgeId)).data;
        this.activityEditDialogVisible = false;
        this.activityDialogVisible = false;
        this.$helpers.toastResponse(this, response, 'Activity is approved for selected race (and or badge).');
        this.open(this.profile.id); 
    },
    async rewardBadge() {
      const request = {
        badge_id: this.selectedBadgeId,
        timestamp: this.selectedDate,
      };
        const response = (await eventManagerService.rewardBadge(this.eventId, this.selectedRaceId, this.profile.id, request)).data;
        this.rewardBadgeDialogVisible = false;
        this.$helpers.toastResponse(this, response, 'Badge is rewarded.');
        this.open(this.profile.id); 
    },
    async approveActivity(activityId) {
      if (confirm('Are you sure you want to approve this activity for all (qualifying) leaderboards in this event?')) {
        const response = (await eventManagerService.approveParticipantActivityDetails(this.eventId, this.profile.id, activityId)).data;
        this.$helpers.toastResponse(this, response, 'Activity is approved. Updating the results might take a few minutes.');
        this.open(this.profile.id); 
      }
    },
    async rejectActivity(activityId) {
      if (confirm('Are you sure you want to remove this activity from all leaderboards in this event?')) {
        const response = (await eventManagerService.rejectParticipantActivityDetails(this.eventId, this.profile.id, activityId)).data;
        this.$helpers.toastResponse(this, response, 'Activity is rejected and removed from results.');
        this.open(this.profile.id); 
      }
    },

    async saveActivity() {
      var response;
      if (this.activity.id) {
        response = (await eventManagerService.editParticipantActivity(this.eventId, this.profile.id, this.activity.id, this.activity)).data;
      }
      else {
        response = (await eventManagerService.addParticipantActivity(this.eventId, this.profile.id, this.activity)).data;
      }
      this.$helpers.toastResponse(this, response, 'Activity is saved successfully.');
      if (response.status == "OK") {
        this.activityEditDialogVisible = false;
        this.open(this.profile.id); 
      }
    },
    async deleteActivity(activityId) {
      if (confirm('Are you sure you want to DELETE this activity?')) {
        await eventManagerService.deleteParticipantActivity(this.eventId, this.profile.id, activityId);
        const response = { status: "OK"}
        this.$helpers.toastResponse(this, response, 'Activity is deleted successfully.');
        this.open(this.profile.id); 
      }
    },
    async showActivityEditorForAdd() {
      var startOfDay = DateTime.now().startOf('day');
      var rezoned = startOfDay.setZone(this.event.timezone, { keepLocalTime: true });
      this.activity = {
        start: rezoned.toISO(),
        type: 'RUNNING',
        provider: 'manual_entry',
        time_s: 0,
        elevation_gain: 0,
        dist: 0,
        steps: 0,
      };
      this.activityEditDialogVisible = true;
    },
    async showActivityEditor(activityId) {
        const response = (await eventManagerService.getParticipantActivityDetails(this.eventId, this.profile.id, activityId)).data;
        this.activity = response;
        console.log('Loaded activity', this.activity);
        this.activityEditDialogVisible = true;
    },

    async showActivityDetails(activityId) {
        this.activityDialogVisible = true;
        const response = (await eventManagerService.getParticipantActivityDetails(this.eventId, this.profile.id, activityId)).data;
        this.activity = response;

        this.$nextTick(() => {
          if (this.$refs.leaflet && this.hasTrackChallengeOrGeofence) {
            var map = this.$refs.leaflet.map // work as expected
            map.invalidateSize();
            var polyDecoded = this.activity.route_summary == null ? [] : PolylineUtil.decode(this.activity.route_summary);

            var polys = [];
            for (var race of this.event.races) {
              if (race.route) {
                //console.log('route match between', race.id, this.activity.route_summary, 'and', race.route);
                var racePolyDecoded = PolylineUtil.decode(race.route);
                polys.push(window.L.polyline(racePolyDecoded, {color: 'white', weight: 12, stroke: true, fill: false}));
                polys.push(window.L.polyline(racePolyDecoded, {color: 'red', weight: 8, stroke: true, fill: false}));
              }
              if (race.geofence) {
                const decoded = PolylineUtil.decode(race.geofence);
                // add nice fat outline
                window.L.polygon(decoded, { color: '#ffffff', opacity: 1, weight: 10, stroke: true, fill: false, dashArray: null }).addTo(map);
                window.L.polygon(decoded, { color: '#8bc34a', opacity: 1, weight: 5, stroke: true, fill: false, dashArray: null }).addTo(map);
              }
            }
            console.log('Detailed map', 'adding poly', polyDecoded.length);
            polys.push(window.L.polyline(polyDecoded, {color: 'white', weight: 5, stroke: true, fill: false}));
            polys.push(window.L.polyline(polyDecoded, {color: 'blue', weight: 3, stroke: true, fill: false}));

            var group = window.L.featureGroup(polys).addTo(map);
            map.fitBounds(group.getBounds());
          }
        })

    },

    async updateParticipant() {
      console.log('Updating participant details', this.participantDetails);
      const response = (await eventManagerService.putParticipant(this.eventId, this.profile.id, this.participantDetails)).data;
      this.$toast.success('Updated race settings');
      await this.open(this.profile.id);
      this.participantDetailsDialog = false;
    },

    async refreshAppSync() {
      console.log('Deleting debug meta data...');
      const response = (await eventManagerService.sendParticipantDeviceAction(this.eventId, this.profile.id, 'sync')).data;
      this.$helpers.toastResponse(this, response, 'Requested app to sync. It might take a little while for data to arrive.');
      await new Promise(r => setTimeout(r, 5000));
      await this.open(this.profile.id);
    },

    async refreshAppStatus() {
      console.log('Deleting debug meta data...');
      this.refreshingAppStatus = true;
      await eventManagerService.deleteParticipantMeta(this.eventId, this.profile.id, 'dbg.app');
      await eventManagerService.deleteParticipantMeta(this.eventId, this.profile.id, 'dbg.sync');
      console.log('Requesting new data...');
      const response = (await eventManagerService.sendParticipantDeviceAction(this.eventId, this.profile.id, 'debug-app')).data;
      this.$helpers.toastResponse(this, response, 'Requested app debug info.');
      await new Promise(r => setTimeout(r, 1000));
      const responseSync = (await eventManagerService.sendParticipantDeviceAction(this.eventId, this.profile.id, 'debug-sync')).data;
      this.$helpers.toastResponse(this, responseSync, 'Requested sync debug info.');
      await new Promise(r => setTimeout(r, 5000));
      await this.open(this.profile.id);
      this.refreshingAppStatus = false;
    },

  },
  computed: {
    showDebugInfo() {
      return this.$route.query.debug=='true';
    },
    firstDayAllowed() {
      if (!this.event || (!this.event.allowed_history_days && this.event.allowed_history_days !== 0) || !this.data || !this.data.join_event_date) {
        return null;
      }
      return DateTime.fromISO(this.data.join_event_date).plus({days: -1*this.event.allowed_history_days});
    },
    lastDayAllowed() {
      if (!this.event || !this.event.max_participation_days || !this.data || !this.data.join_event_date) {
        return null;
      }
      return DateTime.fromISO(this.data.join_event_date).plus({days: this.event.max_participation_days});
    },
    activityEntryUnit() {
      if (this.activity && ['ROWING', 'SWIMMING'].some(x => x === this.activity.type)) {
        return 'METRIC';
      }
      return this.event.unit;
    },
    elevationUnitTypeDisplay() {
      return this.activityEntryUnit == 'METRIC' ? 'm' : 'ft';
    },
    showTeamCategoryOption() {
      return this.event && (
        (this.event.join_udf_1_field && this.event.join_udf_1_field.team_category)
        || (this.event.join_udf_2_field && this.event.join_udf_2_field.team_category)
        || (this.event.join_udf_3_field && this.event.join_udf_3_field.team_category)
      );
    },
    showRewardBadgeOption() {
      return true;
      return this.event && this.event.races && this.event.races.some(x => x.badge_scoring === 'MANUAL_CLAIM');
    },
    showCustomField() {
      return this.activity && (['VOLUNTEERING', 'OTHER'].some(x => x === this.activity.type))
    },
    showLeaderboardSelection() {
      return this.event.races.filter(x => x.import_id).length > 0;
    },
    customFieldLabel() {
      return this.showCustomField && this.event.races.filter(x => x.scoring == 'CUSTOM').length > 0 && this.event.races.filter(x => x.scoring == 'CUSTOM')[0].custom;
    },
    hasTrackChallengeOrGeofence() {
      return this.event && this.event.races.some(x => x.scoring == 'TRACK' || x.geofence);
    },
    showElevation() {
      return this.activity && !['SWIMMING'].some(x => x === this.activity.type) && !activityService.zeroDistanceActivities(this.activity);
    },
    eventIsLocked() {
      return this.event && (this.event.locked || this.event.auto_locked);
    },
    computedElevationGain:{
      get () {
        return this.activity ? this.activity.elevation_gain : null
      },
      set (value) {
        this.activity.elevation_gain = value === /*must be triple!*/ 0 ? null /* store 0 as null */ : value || null // coerce to null (except for zero!) (empty string is otherwise an issue)
      }
    },

  },
  watch: {
    async selectedRaceId() {
      var response = await eventManagerService.getBadges(this.event.id, this.selectedRaceId);
      this.badgesForSelectedRace = response.data.data;
    }
  }
};
</script>
<style lang="scss">
</style>

